import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import CorpLogo from "../images/francoisLogoNonBorder.svg"

const StyledDiv = styled.div`
  width: 100%;
  height: 60px;
  position: fixed;
  z-index: 999;
  background: white;
  box-shadow: 0px 1px 0px #dadada;
  header {
    width: 100%;
    height: 100%;
    padding: 0 5%;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    .headRight {
      width: 60px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      img {
        width: 80%;
      }
    }
    .langList {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;
      a {
        padding: 0 0.62em;
      }
      span {
        display: block;
        height: 16px;
        width: 1px;
        background: #acacac;
        content: "";
      }
    }
  }
`

const LangHeader = () => {
  const pathname = typeof window !== "undefined" ? window.location.pathname : ""
  const displayedLanguage = pathname.split("/")[1]
  return (
    <StyledDiv>
      <header>
        <div className="headRight">
          <Link to={`/${displayedLanguage}/toppage/`}>
            <img src={CorpLogo} alt="francoisLogo" />
          </Link>
        </div>
        <div className="langList">
          <Link to="/">JP</Link>
          <span></span>
          <Link to="/en/toppage/">EN</Link>
          <span></span>
          <Link to="/cn/toppage/">CN</Link>
        </div>
      </header>
    </StyledDiv>
  )
}

export default LangHeader
