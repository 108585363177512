// import libraries
import React from "react"
import styled, { ThemeProvider, css } from "styled-components"
import { media } from "../styles/media"

// import styles and custom react hooks
import theme from "../styles/theme"
import GlobalStyle from "../styles/globalStyle"

// import components
import LangHeader from "./LangHeader"
import LangFooter from "./LangFooter"

const StyledLayoutWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  font-size: 16px;
  color: #292929;
  main {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-top: 60px;
    font-family: "Roboto";
  }
  ${() =>
    media.sp(css`
      main {
        font-size: 14px;
      }
    `)}
`

const LangLayout = ({ children }) => {
  return (
    <StyledLayoutWrapper>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <LangHeader />
        <main>{children}</main>
        <LangFooter />
      </ThemeProvider>
    </StyledLayoutWrapper>
  )
}

export default LangLayout
