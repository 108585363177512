import React from "react"
import styled, { css } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import Image from "gatsby-image"
import FooterLogoImage from "../images/francoisLogoBorder2.svg"
import { media } from "../styles/media"
import { BiCopyright } from "react-icons/bi"
// import { FaExternalLinkAlt } from "react-icons/fa"
import { GrYoutube, GrFacebook } from "react-icons/gr"

const StyledFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  z-index: 0;
  .footBackImg {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    .gatsby-image-wrapper {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    &:after {
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      top: 0;
      right: 0;
      z-index: 0;
      position: absolute;
    }
  }
  #footer {
    background: transparent;
    padding: 30px 0 10px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    .footerTopLogo {
      width: 100%;
      margin-bottom: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 80px;
      }
    }
  }
  .snsSection {
    width: 100%;
    padding: 50px 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    svg {
      margin: 0 30px;
      transform: scale(1.6);
      path {
        color: white;
      }
    }
  }
  .footerCopy p {
    color: white;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    svg {
      margin-right: 0.62em;
      path {
        color: white;
      }
    }
  }
  ${() =>
    media.sp(css`
      #footer {
        margin-left: 0;
        padding-top: 30px;
        .footerTopLogo {
          img {
            width: 50px;
          }
        }
      }
    `)}
`

const StyledLang = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  p {
    color: white;
    margin-bottom: 0.32em;
  }
  .langList {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    a {
      padding: 0.62em 1.62em;
      color: white;
    }
    span {
      display: block;
      content: "";
      height: 20px;
      width: 1px;
      background: #dadada;
    }
  }
`

const LangFooter = () => {
  const data = useStaticQuery(query)
  return (
    <StyledFooterWrapper>
      <div className="footBackImg">
        <Image
          fluid={data.zoo.footerMedia.childImageSharp.fluid}
          alt="footer background image"
        />
      </div>
      <footer id="footer">
        <div className="footerTopLogo">
          <img src={FooterLogoImage} alt="logo" />
        </div>

        <StyledLang>
          <p>Language</p>
          <div className="langList">
            <Link to="/en/toppage/">EN</Link>
            <span></span>
            <Link to="/cn/toppage/">CN</Link>
          </div>
        </StyledLang>

        {/* SNS section */}
        <div className="snsSection">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/user/francoisjp"
          >
            <GrYoutube />
          </a>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.facebook.com/francois.co.jp"
          >
            <GrFacebook />
          </a>
        </div>

        {/* Copy right section */}
        <div className="footerCopy">
          <p>
            <BiCopyright />
            2021 Francois Co., Ltd.
          </p>
        </div>
      </footer>
    </StyledFooterWrapper>
  )
}

export const query = graphql`
  {
    zoo: strapiIndexParts {
      footerMedia {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

export default LangFooter
