import React from "react"
import styled, { css } from "styled-components"
import { media } from "../styles/media"
import Image from "gatsby-image"

const StyledDiv = styled.div`
  height: 230px;
  overflow: hidden;
  position: relative;
  .gatsby-image-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 8;
  }
  .headerText {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 50px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    justify-content: flex-end;
    h2 {
      color: white;
      font-size: 1.32em;
      line-height: 1em;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-end;
      width: 350px;
      text-align: left;
    }
    h2:after {
      display: block;
      content: "";
      height: 1px;
      background: white;
      width: 120%;
      margin: 10px 0 10px;
    }
    p {
      color: white;
      width: 400px;
    }
  }
  ${() =>
    media.tablet(css`
      .headerText h2 {
        width: 250px;
      }
      .headerText p {
        width: 300px;
      }
    `)}
  ${() =>
    media.sp(css`
      height: 200px;
      .headerText {
        padding: 50px 5%;
        h2 {
          width: 200px;
        }
        p {
          width: 240px;
        }
      }
    `)}
`

const PageHeaderMedia = props => {
  return (
    <StyledDiv>
      <Image fluid={props.fluid} />
      <div className="headerText">
        <h2>{props.pageTitles}</h2>
        {props.pageText && <p>{props.pageText}</p>}
      </div>
    </StyledDiv>
  )
}

export default PageHeaderMedia
